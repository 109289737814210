<template>
  <el-dialog
    v-dialogDrag
    :title="editType == 2 ? '编辑' : '新增'"
    :visible="addVisible"
    width="900px"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    custom-class="install-scene-dialog"
    @open="openDialog"
  >
    <el-form label-width="120px" ref="addForm" :inline="true" :model="addForm">
      <el-form-item label="发布平台" prop="platformType">
        <e6-vr-select
          v-model="addForm.platformType"
          :data="platformTypeList"
          placeholder="发布平台"
          title="发布平台"
          clearable
          virtual
          :is-title="true"
          :disabled="editType == 1"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="项目名称" prop="projectName">
        <el-input
          v-model="addForm.projectName"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="项目分支" prop="projectBranch">
        <el-input
          v-model="addForm.projectBranch"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="版本" prop="projectVersion">
        <el-input
          v-model="addForm.projectVersion"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="期望发布时间" prop="expectReleaseTime">
        <el-input
          v-model="addForm.expectReleaseTime"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="发布人" prop="releaseUser">
        <el-input
          v-model="addForm.releaseUser"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="DB变更" prop="dbChange">
        <el-input
          v-model="addForm.dbChange"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="修改代码列表" prop="codeChange">
        <el-input
          v-model="addForm.codeChange"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="测试人" prop="testUser">
        <el-input
          v-model="addForm.testUser"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="测试项" prop="testItem">
        <el-input
          v-model="addForm.testItem"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="测试结论" prop="testConclusion">
        <e6-vr-select
          v-model="addForm.testConclusion"
          :data="options"
          placeholder="测试结论"
          title="测试结论"
          clearable
          virtual
          :is-title="true"
          :disabled="editType == 1"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="菜单系统配置路径" prop="menuUrl">
        <el-input
          v-model="addForm.menuUrl"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="Reviewer" prop="reviewerUser">
        <el-input
          v-model="addForm.reviewerUser"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="Review结果" prop="reviewConclusion">
        <e6-vr-select
          v-model="addForm.reviewConclusion"
          :data="options"
          placeholder="Review结果"
          title="Review结果"
          clearable
          virtual
          :is-title="true"
          :disabled="editType == 1"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="产品" prop="productUser">
        <el-input
          v-model="addForm.productUser"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="研发" prop="developUser">
        <el-input
          v-model="addForm.developUser"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="测试" prop="allTestUser">
        <el-input
          v-model="addForm.allTestUser"
          :disabled="editType == 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="版本描述" prop="functionDescription">
        <vue-editor
          v-model="addForm.functionDescription"
          :disabled="editType == 1"
        ></vue-editor>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" slot="footer" v-if="editType != 1">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { VueEditor } from "vue2-editor";
import { printError } from "@/utils/util";
import { saveReleaseRecord, getReleaseRecordDetail } from "@/api";
export default {
  name: "add",
  components: { VueEditor },
  data() {
    return {
      dialogLoading: false,
      addForm: {
        platformType: "",
        projectName: "",
        projectBranch: "",
        projectVersion: "",
        expectReleaseTime: "",
        releaseUser: "",
        functionDescription: "",
        dbChange: "",
        codeChange: "",
        testUser: "",
        testItem: "",
        testConclusion: "",
        menuUrl: "",
        reviewerUser: "",
        reviewConclusion: "",
        productUser: "",
        developUser: "",
        allTestUser: ""
      },
      platformTypeList: [
        {
          id: 1,
          label: "工单"
        },
        {
          id: 3,
          label: "易修哥"
        }
      ],
      options: [
        {
          id: 1,
          label: "通过"
        },
        {
          id: 0,
          label: "不通过"
        }
      ]
    };
  },
  props: ["addVisible", "editType", "clickId"],
  mixins: [base],
  computed: {},
  watch: {},
  created() {},
  methods: {
    openDialog() {
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
      if (this.editType) {
        this.getDetail();
      }
    },
    async getDetail() {
      try {
        this.dialogLoading = true;
        let res = await getReleaseRecordDetail({
          id: this.clickId
        });
        if (res.code == "OK") {
          this.addForm = { ...res.data };
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    changeMaterialNo(val, node, row) {
      row.materialNoStr = node.materialNo;
    },
    //点击确定
    confirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          console.log(this.addForm, "~~~");
          this.saveReleaseRecordReq();
        }
      });
    },
    //新增
    async saveReleaseRecordReq() {
      try {
        this.dialogLoading = true;
        let res = await saveReleaseRecord({
          ...this.addForm,
          materialVOList: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addForm = {
        platformType: 1,
        projectName: "",
        projectBranch: "",
        projectVersion: "",
        expectReleaseTime: "",
        releaseUser: "",
        functionDescription: "",
        dbChange: "",
        codeChange: "",
        testUser: "",
        testItem: "",
        testConclusion: "",
        menuUrl: "",
        reviewerUser: "",
        reviewConclusion: "",
        productUser: "",
        developUser: "",
        allTestUser: ""
      };

      this.$emit("close", "addVisible");
    },
    //点击添加
    handleAdd() {
      let tableData = this.tableData;
      tableData.push({
        materialNo: "",
        materialNoStr: "",
        amount: "",
        bizNoList: []
      });
      this.tableData = tableData;
    },
    //删除单行
    deleteColumn(index) {
      let tableData = _.cloneDeep(this.tableData);
      tableData.splice(index, 1);
      this.tableData = tableData;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog {
  .el-dialog__body {
    padding-top: 20px;
    [class^="e6-icon"] {
      height: 28px;
      color: #46bfea;
      line-height: 28px;
      font-style: normal;
      text-align: center;
      padding: 0 10px;
      cursor: pointer;
      border-radius: 3px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      padding: 0;
      font-size: 20px;
    }
  }
  .el-form {
    .el-input {
      width: 300px;
    }
  }
}
</style>
